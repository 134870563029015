import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import './style.css'
import * as dat from 'dat.gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import gsap from 'gsap'
import blink from '../blink-detection/index.js';

var activate = true;

const updateModelStatus = () => {
  const status = document.querySelector('.model-status');
  if (status) {
    status.innerHTML = 'Model loaded! You can start!';
    document.getElementById('loading').style.display = "none";
    document.getElementById('all').style.display = "block";

    status.classList.add('fade-out');
    status.classList.remove('model-status');
  }
};

const videoElement = document.querySelector('video');
let lastOpacity = 1;
function toggleMode() {
  console.log('blink')
  // cloud();
  let style = document.getElementById('dark-mode-style');
  let currentMode = document.getElementById('current-mode');

  // Ensure opacity doesnt go below 0
  if(document.getElementById('overlay').style.opacity <= 0){
    lastOpacity = 0;
    document.getElementById('overlay').style.opacity = lastOpacity;
  }

}

var raf;
// if(document.getElementById('overlay').style.opacity == 0){
//   last += 0.5;
//   console.log('last overlay tick %d opacity %d', last, document.getElementById('overlay').style.opacity);
//   document.getElementById('overlay').style.opacity = last;
// }

const init = async () => {
  await blink.loadModel();
  await blink.setUpCamera(videoElement);

  // let blinkIndicator = document.getElementById('blink-indicator');
  let body = document.getElementsByTagName('body');
  const predict = async () => {
    let result = await blink.getBlinkPrediction();
    updateModelStatus();

    if (result) {
      console.log('result blink', result)
      if (result.longBlink) {
        cloud();
        console.log('blinkkkkk')
      }
    }

    // if(document.getElementById('overlay').style.opacity >= 0){
    //   document.getElementById('overlay').style.opacity += 0.05;
    // }

    raf = requestAnimationFrame(predict);
  };
  predict();
};

init();


// THREE JS
let camera, scene, renderer, mixer, sceneBio, rendererBio, cameraBio;
let objs = []
let images = []

// Canvas
const canvas = document.querySelector('canvas.canvasGL')
const canvasBio = document.querySelector('canvas.canvasBio')

let sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

function initThree() {

  // Scene
  scene = new THREE.Scene()
  sceneBio = new THREE.Scene()

  const texLoad = new THREE.TextureLoader();

  // Base camera
  camera = new THREE.PerspectiveCamera(100, sizes.width / sizes.height, 1, 10000)
  cameraBio = new THREE.PerspectiveCamera(100, sizes.width / sizes.height, 1, 10000)

  camera.position.x = 0
  camera.position.y = 0
  camera.position.z = 10;
  cameraBio.position.x = 0
  cameraBio.position.y = 0;
  cameraBio.position.z = 10;
  scene.add(camera)
  sceneBio.add(camera);

  //Modeling light
  const hemiLight = new THREE.HemisphereLight(0xffffff, 2);
  hemiLight.position.set(0, 300, 0);
  scene.add(hemiLight);
  sceneBio.add(hemiLight);

  const dirLight = new THREE.DirectionalLight(0xffffff, 2);
  dirLight.position.set(75, 300, -75);
  scene.add(dirLight);
  sceneBio.add(hemiLight);

  let initPosX;
  let initPosZ;
  //IMAGES 
  const geo = new THREE.BoxGeometry(3, 2, 0.01);
  for (let i = 1; i <= 5; i++) {
      const mat = new THREE.MeshBasicMaterial({
          map: texLoad.load(`../photo/${i}.jpg`)
      })

      const img = new THREE.Mesh(geo, mat)
      img.position.x = i * 3 - 9;
      initPosX = i *3 ;
      
      img.position.z = 0.05 * i;
      initPosZ = 0.05 * i;

      img.position.y = 8;
      img.rotation.x = Math.PI;
      img.castShadow = true;
      img.receiveShadow = true;

      img.userData.name=`IMG${i}`
      img.userData.draggable = true;

      images.push(img);

      scene.add(img);
  }

  for (let i = 1; i <= 5; i++) {
    const mat = new THREE.MeshBasicMaterial({
        map: texLoad.load(`../photo/${5+i}.jpg`)
    })

    const img = new THREE.Mesh(geo, mat)
    img.position.x = i * 3 - 9;
    initPosX = i *3 - 8;
    
    img.position.z = 0.05 * i;
    initPosZ = 0.05 * i;

    img.position.y = 4;
    img.rotation.x = Math.PI;
    img.castShadow = true;
    img.receiveShadow = true;

    img.userData.name=`IMG${i}`
    img.userData.draggable = true;

    images.push(img);

    scene.add(img);
}

for (let i = 1; i <= 5; i++) {
  const mat = new THREE.MeshBasicMaterial({
      map: texLoad.load(`../photo/${10+i}.jpg`)
  })

  const img = new THREE.Mesh(geo, mat)
  img.position.x = i * 3 - 9;
  initPosX = i *3 - 8;
  
  img.position.z = 0.05 * i;
  initPosZ = 0.05 * i;

  img.position.y = 0;
  img.rotation.x = Math.PI;
  img.castShadow = true;
  img.receiveShadow = true;

  img.userData.name=`IMG${i}`
  img.userData.draggable = true;

  images.push(img);

  scene.add(img);
}

for (let i = 1; i <= 5; i++) {
  const mat = new THREE.MeshBasicMaterial({
      map: texLoad.load(`../photo/${15+i}.jpg`)
  })

  const img = new THREE.Mesh(geo, mat)
  img.position.x = i * 3 - 9;
  initPosX = i *3 - 8;
  
  img.position.z = 0.05 * i;
  initPosZ = 0.05 * i;

  img.position.y = -4;
  img.rotation.x = Math.PI;
  img.castShadow = true;
  img.receiveShadow = true;

  img.userData.name=`IMG${i}`
  img.userData.draggable = true;

  images.push(img);

  scene.add(img);
}

for (let i = 1; i <= 5; i++) {
  const mat = new THREE.MeshBasicMaterial({
      map: texLoad.load(`../photo/${20+i}.jpg`)
  })

  const img = new THREE.Mesh(geo, mat)
  img.position.x = i * 3 - 9;
  initPosX = i *3 - 8;
  
  img.position.z = 0.05 * i;
  initPosZ = 0.05 * i;

  img.position.y = -8;
  img.rotation.x = Math.PI;
  img.castShadow = true;
  img.receiveShadow = true;

  img.userData.name=`IMG${i}`
  img.userData.draggable = true;

  images.push(img);

  scene.add(img);
}

  

  scene.traverse((object) => {
      if (object.isMesh) {
          objs.push(object)
          console.log('object', objs)
      }
  })
  const s = 10;
  console.log(images);

  // Table Plane
  const geo_runway = new THREE.PlaneGeometry(s*2, s*2);
  const mat_runway = new THREE.MeshPhysicalMaterial({ color: 0x0000f0, metalness: 0, transmission: 0.5, thickness: 0.5 });
  const runway = new THREE.Mesh(geo_runway, mat_runway);
  runway.position.z = 0;
  runway.position.y = 0;
  runway.rotation.x = Math.PI;
  scene.add(runway);

  // Table Outline
  const linemat = new THREE.LineBasicMaterial({
    color: 0xec0db4
  });
  
  const points = [];
  points.push( new THREE.Vector3( - s, -s, 0 ) );
  points.push( new THREE.Vector3( -s, s, 0 ) );
  points.push( new THREE.Vector3( s, s, 0 ) );
  points.push( new THREE.Vector3( s, -s, 0 ) );
  points.push( new THREE.Vector3( - s, -s, 0 ) );

  const geoline = new THREE.BufferGeometry().setFromPoints( points );
  
  const line = new THREE.Line( geoline, linemat );
  line.matrix.makeRotationFromQuaternion( Math.PI/2 );

  scene.add( line );

  const leg1 = []
  leg1.push( new THREE.Vector3( - s, -s, 0 ) );
  leg1.push( new THREE.Vector3( -s, -s, -15 ) );
  const geoleg1 = new THREE.BufferGeometry().setFromPoints( leg1 );
  const leg1line = new THREE.Line( geoleg1, linemat );
  scene.add( leg1line );

  const leg2 = []
  leg2.push( new THREE.Vector3( -s, s, 0 ) );
  leg2.push( new THREE.Vector3( -s, s, -15 ) );
  const geoleg2 = new THREE.BufferGeometry().setFromPoints( leg2 );
  const leg2line = new THREE.Line( geoleg2, linemat );
  scene.add( leg2line );

  const leg3 = []
  leg3.push( new THREE.Vector3( s, s, 0 ) );
  leg3.push( new THREE.Vector3( s, s, -15 ) );
  const geoleg3 = new THREE.BufferGeometry().setFromPoints( leg3 );
  const leg3line = new THREE.Line( geoleg3, linemat );
  scene.add( leg3line );

  const leg4 = []
  leg4.push( new THREE.Vector3( s, -s, 0 ) );
  leg4.push( new THREE.Vector3( s, -s, -15 ) );
  const geoleg4 = new THREE.BufferGeometry().setFromPoints( leg4 );
  const leg4line = new THREE.Line( geoleg4, linemat );
  scene.add( leg4line );


  // Materials
  const material = new THREE.MeshBasicMaterial({})
  material.color = new THREE.Color(0xff00ff)
  material.transparency = true;


  //Renderer
  renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      alpha: true
  })
  rendererBio = new THREE.WebGLRenderer({
    canvas: canvasBio,
    alpha: true
})
  renderer.setSize(sizes.width, sizes.height)
  rendererBio.setSize(sizes.width, sizes.height)
  document.body.appendChild(renderer.domElement);
  document.body.appendChild(rendererBio.domElement);

  //Window Size
  window.addEventListener('resize', onWindowResize);



  render();
}

function onWindowResize() {
  //Size 
  window.addEventListener('resize', () => {
      // Update sizes
      sizes.width = window.innerWidth
      sizes.height = window.innerHeight

      // Update camera
      camera.aspect = sizes.width / sizes.height
      cameraBio.aspect = sizes.width / sizes.height

      camera.updateProjectionMatrix()
      cameraBio.updateProjectionMatrix()

      // Update renderer
      renderer.setSize(sizes.width, sizes.height)
      rendererBio.setSize(sizes.width, sizes.height)
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
      rendererBio.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  })

  render();
}

function render() {
  renderer.render(scene, camera);
  rendererBio.render(sceneBio, cameraBio);
}

initThree();


// ANIMATION
const clock = new THREE.Clock()
let posZ = 0;
const orbitControl = new OrbitControls( camera, renderer.domElement );
const orbitControlBio = new OrbitControls( cameraBio, rendererBio.domElement );

// const controls = new DragControls(images, camera, renderer.domElement);

const mouse = new THREE.Vector2();
const clickMouse = new THREE.Vector2();
const movedMouse = new THREE.Vector2();
const mouseHold = new THREE.Vector2();

let draggable;
// console.log('drag', draggable)

const raycaster = new THREE.Raycaster()
let imgClicked = false;

document.addEventListener('click', event => {
  // if(draggable){
  //   // draggable = null;
  //   // return;
  //   console.log(`Dropping obj ${draggable.userData.name}`);
  // }
  console.log('clicked');
  clickMouse.x = (event.clientX / sizes.width) * 2 - 1;
  clickMouse.y = (event.clientY / sizes.height) * 2 - 1;

  // raycaster.setFromCamera(mouse, camera);
  // let found = raycaster.intersectObjects(objs);


  // if(found.length > 0 && found[0].object.userData.draggable){
  //   draggable = found[0].object;
  //   console.log(' found?', draggable);
  //   console.log(`Clicked obj ${draggable.userData.name}`);
  // };
  
})

let drop = false;
document.getElementById('back').addEventListener('pointerdown', (e) => {
  document.getElementById('cvContent').style.display = "none";
  document.getElementById('back').style.display = "none";
  document.querySelector('canvas.canvasGL').style.display = "none";
  document.getElementById('pcWindow').style.display = "none";
  document.getElementById('music').style.display = "none";
  document.querySelector('canvas.canvasBio').style.display = "none";
  document.getElementById('bio').style.display = "none";
  document.getElementById('photoWindow').style.display = "none";
  document.getElementById('exhibitionWindow').style.display = "none";
  document.getElementById('backPhoto').style.display = "none";

  document.getElementById('home').style.display = "block";
  activate = true;

})
document.getElementById('backPhoto').addEventListener('pointerdown', (e) => {
  document.querySelector('canvas.canvasGL').style.display = "block";
  document.getElementById('photoWindow').style.display = "none";
  document.getElementById('back').style.display = "block";
  document.getElementById('backPhoto').style.display = "none";

})

document.getElementById('table').addEventListener('pointerdown', (e) => {
  document.getElementById('back').style.display = "block";
  document.querySelector('canvas.canvasGL').style.display = "block";
  document.getElementById('home').style.display = "none";
  activate = false;

  // document.querySelector('body').backgroundImage = url('https://www.macmillandictionary.com/external/slideshow/full/White_full.png');
  // document.getElementById('content').classList.add('active');

  // document.getElementById('content').style.display = "none";
})
document.getElementById('pc').addEventListener('pointerdown', (e) => {
  document.getElementById('back').style.display = "block";
  document.getElementById('pcWindow').style.display = "block";
  document.getElementById('home').style.display = "none";
  activate = false;

  // document.querySelector('body').backgroundImage = url('https://www.macmillandictionary.com/external/slideshow/full/White_full.png');
  // document.getElementById('content').classList.add('active');

  // document.getElementById('content').style.display = "none";
})
document.getElementById('radio').addEventListener('pointerdown', (e) => {
  document.getElementById('back').style.display = "block";
  document.getElementById('music').style.display = "block";
  document.getElementById('home').style.display = "none";
  activate = false;
})

document.getElementById('exhibition').addEventListener('pointerdown', (e) => {
  document.getElementById('back').style.display = "block";
  document.getElementById('exhibitionWindow').style.display = "block";
  document.getElementById('home').style.display = "none";
  activate = false;
})

document.getElementById('person').addEventListener('pointerdown', (e) => {
  document.getElementById('back').style.display = "block";
  document.querySelector('canvas.canvasBio').style.display = "block";
  document.getElementById('bio').style.display = "block";

  document.getElementById('home').style.display = "none";
  activate = false;

  // document.getElementById('content').classList.add('active');

  // document.getElementById('content').style.display = "none";
})
document.getElementById('im').addEventListener('click', (e) => {
  document.getElementById('urlIframe').src = "https://i-m.ekezia.repl.co/";
  document.getElementById('im-desc').style.display = "block";
  document.getElementById('markuqueue-desc').style.display = "none";
  document.getElementById('katalok-desc').style.display = "none";


})
document.getElementById('markuqueue').addEventListener('click', (e) => {
  document.getElementById('urlIframe').src = "https://markuqueue.vercel.app/";
  document.getElementById('im-desc').style.display = "none";
  document.getElementById('markuqueue-desc').style.display = "block";
  document.getElementById('katalok-desc').style.display = "none";
})
document.getElementById('katalok').addEventListener('click', (e) => {
  document.getElementById('urlIframe').src = "https://katalok.vercel.app/";
  document.getElementById('im-desc').style.display = "none";
  document.getElementById('katalok-desc').style.display = "block";
  document.getElementById('markuqueue-desc').style.display = "none";

})
document.addEventListener('pointerup', (e) => {
  // orbitControl.enabled = false;
  console.log('imgClicked', imgClicked);
  let duration = (e.upTime - e.downTime);

  imgClicked = false;
    console.log('duration of hold,', duration);

})
let open;
let descImage = 'photo/1.jpg';

document.addEventListener('pointerdown',event => {

  orbitControl.enabled = true;
  console.log('imgClicked', imgClicked);
  imgClicked = true;
  drop = true;
  raycaster.setFromCamera(mouse, camera);
  let intersects = raycaster.intersectObjects(objs);
  for (const intersect of intersects) {
      for (const object of objs) {
          if (intersect.object == objs[0]) {
            // window.open('https://www.instagram.com');
            descImage = 'photo/2.jpg';
            console.log('descimg', descImage);

            if(imgClicked){


            }

          } else if (intersect.object == objs[1] && imgClicked) {
            
          } else if (intersect.object == objs[2] && imgClicked) {
            
          } else if (intersect.object == objs[3] && imgClicked) {
            
          } 
      }
  }
})

orbitControl.enabled = true;
orbitControlBio.enabled = true;

document.addEventListener('pointermove', event => {
  imgClicked = true;

  movedMouse.x = (event.clientX / sizes.width) * 4 - 1;
  movedMouse.y = -(event.clientY / sizes.height) * 4 + 1;
  mouse.x = (event.clientX / sizes.width) * 2 - 1;
  mouse.y = -(event.clientY / sizes.height) * 2 + 1;
})


let activateCloud = false;
let cloudOpacity = 1;
// document.getElementById('all').style.opacity = 0.1;
function cloud() {
  // Blink
  console.log('blinkCloud', cloudOpacity);
  cloudOpacity += 0.2;
  // document.querySelector('body').style.filter = 'blur('+cloudOpacity+'px);';
  document.querySelector('body').style.opacity = cloudOpacity;

  // document.getElementById('overlay').style.opacity = cloudOpacity; 
  console.log('last toggle %d cloud %d', cloudOpacity, document.querySelector('body').style.opacity);

  // Ensure opacity doesnt go below 0
  if(document.querySelector('body').style.opacity >= 1){
    cloudOpacity = 1;
    document.querySelector('body').style.opacity = cloudOpacity;
  }

  // Cloud
  // if(document.getElementById('overlay').style.opacity <= 0){
  //   console.log('current overlay cloud')
  //   activateCloud = true;
  // }
}
function tick() {
  console.log('activate?', activate);

  const delta = clock.getDelta() //delta has to be initialized before elapsedTime

  const elapsedTime = clock.getElapsedTime();

  if(activate){
    if(cloudOpacity >= 0.1){
      cloudOpacity -= elapsedTime * 0.00001;
      // delete just for testing
    }
    else if(cloudOpacity <= 0.1){
      cloudOpacity = 0.1;
    }
  }
  else if(!activate){
    cloudOpacity = 1;
  }

  document.querySelector('body').style.opacity = cloudOpacity;
  console.log('elapsed cloud opacity', cloudOpacity);
  

  let zoom = 0;

  //Orbit Control
  orbitControlBio.update();
  let whichObj;

//clicking menu check
  
  raycaster.setFromCamera(mouse, camera);
    let intersects = raycaster.intersectObjects(objs);
    for (const intersect of intersects) {
        gsap.to(intersect.object.scale, { x: 3, y: 3 });
        for (const object of objs) {
            if (intersect.object == objs[0]) {
              orbitControl.enable = false;
              // objs[0].position.x = mouse.x * 5;
              // objs[0].position.y = mouse.y * 5;
              if(imgClicked){
              }

            } else if (intersect.object == objs[1] && imgClicked) {
              // objs[1].position.x = mouse.x * 5;
              // objs[1].position.y = mouse.y * 5;
            } else if (intersect.object == objs[2] && imgClicked) {
              // objs[2].position.x = mouse.x * 5;
              // objs[2].position.y = mouse.y * 5;
            } else if (intersect.object == objs[3] && imgClicked) {
              // objs[3].position.x = mouse.x * 5;
              // objs[3].position.y = mouse.y * 5;
            } 
        }
    }
  

  function moveObj(whichObj){
    console.log('which obj', whichObj);
    if(imgClicked){
      // objs[whichObj].position.x -= mouse.x;
      // objs[whichObj].position.y += 1;

    }  
  }
  moveObj(whichObj);

  // FOR NON MOVING OBJECTS
  for (const object of objs) {
      if (!intersects.find(intersect => intersect.object === object)) {
          gsap.to(object.scale, { x: 1, y: 1 })
          // gsap.to(object.rotation, { y: 0 })
      }
  }


  if (mixer) {
      mixer.update(delta);
      console.log('mixer', mixer);
  }

  // Render
  renderer.render(scene, camera);
  rendererBio.render(sceneBio, cameraBio)

  // Call tick again on the next frame
  window.requestAnimationFrame(tick)
}

tick();

// Click Photography
let clicked = false;
canvas.addEventListener('pointerdown', menuClick);
function menuClick(event) {
        clicked = true;
    console.log('clicked?', clicked);
    raycaster.setFromCamera(mouse, camera);

    let intersects = raycaster.intersectObjects(scene.children);

    for (const intersect of intersects) {
        for (const object of objs) {
            if (intersect.object == objs[0] && clicked == true) {
                clicked = false;
                console.log('show menu 1')
                document.getElementById('photoWindow').style.display = "block";
                document.querySelector('canvas.canvasGL').style.display = "none";
                document.getElementById('back').style.display = "none";
                document.getElementById('backPhoto').style.display = "block";

                document.getElementById('images1').style.display = "block";
                document.getElementById('images2').style.display = "none";
                document.getElementById('images3').style.display = "none";
                document.getElementById('images4').style.display = "none";
                document.getElementById('images5').style.display = "none";
                document.getElementById('images6').style.display = "none";
                document.getElementById('images7').style.display = "none";
                document.getElementById('images8').style.display = "none";
                document.getElementById('images9').style.display = "none";
                document.getElementById('images10').style.display = "none";
                document.getElementById('images11').style.display = "none";
                document.getElementById('images12').style.display = "none";
                document.getElementById('images13').style.display = "none";
                document.getElementById('images14').style.display = "none";
                document.getElementById('images15').style.display = "none";
                document.getElementById('images16').style.display = "none";
                document.getElementById('images17').style.display = "none";
                document.getElementById('images18').style.display = "none";
                document.getElementById('images19').style.display = "none";
                document.getElementById('images20').style.display = "none";
                document.getElementById('images21').style.display = "none";
                document.getElementById('images22').style.display = "none";
                document.getElementById('images23').style.display = "none";
                document.getElementById('images24').style.display = "none";
                document.getElementById('images25').style.display = "none";
            } else if (intersect.object == objs[1] && clicked == true) {
                clicked = false;
                console.log('show menu 2')
                document.getElementById('photoWindow').style.display = "block";
                document.querySelector('canvas.canvasGL').style.display = "none";
                document.getElementById('back').style.display = "none";
                document.getElementById('backPhoto').style.display = "block";

                document.getElementById('images2').style.display = "block";
                document.getElementById('images1').style.display = "none";
                document.getElementById('images3').style.display = "none";
                document.getElementById('images4').style.display = "none";
                document.getElementById('images5').style.display = "none";
                document.getElementById('images6').style.display = "none";
                document.getElementById('images7').style.display = "none";
                document.getElementById('images8').style.display = "none";
                document.getElementById('images9').style.display = "none";
                document.getElementById('images10').style.display = "none";
                document.getElementById('images11').style.display = "none";
                document.getElementById('images12').style.display = "none";
                document.getElementById('images13').style.display = "none";
                document.getElementById('images14').style.display = "none";
                document.getElementById('images15').style.display = "none";
                document.getElementById('images16').style.display = "none";
                document.getElementById('images17').style.display = "none";
                document.getElementById('images18').style.display = "none";
                document.getElementById('images19').style.display = "none";
                document.getElementById('images20').style.display = "none";
                document.getElementById('images21').style.display = "none";
                document.getElementById('images22').style.display = "none";
                document.getElementById('images23').style.display = "none";
                document.getElementById('images24').style.display = "none";
                document.getElementById('images25').style.display = "none";
                // document.getElementById('photoWindow').style.display = "block";
            } else if (intersect.object == objs[2]  && clicked == true) {
                clicked = false;
                console.log('show menu 3')
                document.getElementById('photoWindow').style.display = "block";
                document.querySelector('canvas.canvasGL').style.display = "none";
                document.getElementById('back').style.display = "none";
                document.getElementById('backPhoto').style.display = "block";

                document.getElementById('images2').style.display = "none";
                document.getElementById('images1').style.display = "none";
                document.getElementById('images3').style.display = "block";
                document.getElementById('images4').style.display = "none";
                document.getElementById('images5').style.display = "none";
                document.getElementById('images6').style.display = "none";
                document.getElementById('images7').style.display = "none";
                document.getElementById('images8').style.display = "none";
                document.getElementById('images9').style.display = "none";
                document.getElementById('images10').style.display = "none";
                document.getElementById('images11').style.display = "none";
                document.getElementById('images12').style.display = "none";
                document.getElementById('images13').style.display = "none";
                document.getElementById('images14').style.display = "none";
                document.getElementById('images15').style.display = "none";
                document.getElementById('images16').style.display = "none";
                document.getElementById('images17').style.display = "none";
                document.getElementById('images18').style.display = "none";
                document.getElementById('images19').style.display = "none";
                document.getElementById('images20').style.display = "none";
                document.getElementById('images21').style.display = "none";
                document.getElementById('images22').style.display = "none";
                document.getElementById('images23').style.display = "none";
                document.getElementById('images24').style.display = "none";
                document.getElementById('images25').style.display = "none";
            } else if (intersect.object == objs[3]  && clicked == true) {
                clicked = false;
                console.log('show menu 4')
                document.querySelector('canvas.canvasGL').style.display = "none";
                document.getElementById('back').style.display = "none";
                document.getElementById('backPhoto').style.display = "block";
                document.getElementById('photoWindow').style.display = "block";

                document.getElementById('images1').style.display = "none";
                document.getElementById('images2').style.display = "none";
                document.getElementById('images3').style.display = "none";
                document.getElementById('images4').style.display = "block";
                document.getElementById('images5').style.display = "none";
                document.getElementById('images6').style.display = "none";
                document.getElementById('images7').style.display = "none";
                document.getElementById('images8').style.display = "none";
                document.getElementById('images9').style.display = "none";
                document.getElementById('images10').style.display = "none";
                document.getElementById('images11').style.display = "none";
                document.getElementById('images12').style.display = "none";
                document.getElementById('images13').style.display = "none";
                document.getElementById('images14').style.display = "none";
                document.getElementById('images15').style.display = "none";
                document.getElementById('images16').style.display = "none";
                document.getElementById('images17').style.display = "none";
                document.getElementById('images18').style.display = "none";
                document.getElementById('images19').style.display = "none";
                document.getElementById('images20').style.display = "none";
                document.getElementById('images21').style.display = "none";
                document.getElementById('images22').style.display = "none";
                document.getElementById('images23').style.display = "none";
                document.getElementById('images24').style.display = "none";
                document.getElementById('images25').style.display = "none";
            }
            else if (intersect.object == objs[4]  && clicked == true) {
              clicked = false;
              console.log('show menu 5')
              document.querySelector('canvas.canvasGL').style.display = "none";
              document.getElementById('back').style.display = "none";
              document.getElementById('backPhoto').style.display = "block";
              document.getElementById('photoWindow').style.display = "block";

              document.getElementById('images1').style.display = "none";
              document.getElementById('images2').style.display = "none";
              document.getElementById('images3').style.display = "none";
              document.getElementById('images4').style.display = "none";
              document.getElementById('images5').style.display = "block";
              document.getElementById('images6').style.display = "none";
              document.getElementById('images7').style.display = "none";
              document.getElementById('images8').style.display = "none";
              document.getElementById('images9').style.display = "none";
              document.getElementById('images10').style.display = "none";
              document.getElementById('images11').style.display = "none";
              document.getElementById('images12').style.display = "none";
              document.getElementById('images13').style.display = "none";
              document.getElementById('images14').style.display = "none";
              document.getElementById('images15').style.display = "none";
              document.getElementById('images16').style.display = "none";
              document.getElementById('images17').style.display = "none";
              document.getElementById('images18').style.display = "none";
              document.getElementById('images19').style.display = "none";
              document.getElementById('images20').style.display = "none";
              document.getElementById('images21').style.display = "none";
              document.getElementById('images22').style.display = "none";
              document.getElementById('images23').style.display = "none";
              document.getElementById('images24').style.display = "none";
              document.getElementById('images25').style.display = "none";
          }
          else if (intersect.object == objs[5]  && clicked == true) {
            clicked = false;
            console.log('show menu 6');
            document.querySelector('canvas.canvasGL').style.display = "none";
            document.getElementById('back').style.display = "none";
            document.getElementById('backPhoto').style.display = "block";
            document.getElementById('photoWindow').style.display = "block";

            document.getElementById('images1').style.display = "none";
            document.getElementById('images2').style.display = "none";
            document.getElementById('images3').style.display = "none";
            document.getElementById('images4').style.display = "none";
            document.getElementById('images5').style.display = "none";
            document.getElementById('images6').style.display = "block";
            document.getElementById('images7').style.display = "none";
            document.getElementById('images8').style.display = "none";
            document.getElementById('images9').style.display = "none";
            document.getElementById('images10').style.display = "none";
            document.getElementById('images12').style.display = "none";
            document.getElementById('images13').style.display = "none";
            document.getElementById('images14').style.display = "none";
            document.getElementById('images15').style.display = "none";
            document.getElementById('images16').style.display = "none";
            document.getElementById('images17').style.display = "none";
            document.getElementById('images18').style.display = "none";
            document.getElementById('images19').style.display = "none";
            document.getElementById('images20').style.display = "none";
            document.getElementById('images21').style.display = "none";
            document.getElementById('images22').style.display = "none";
            document.getElementById('images23').style.display = "none";
            document.getElementById('images24').style.display = "none";
            document.getElementById('images25').style.display = "none";


        }
        else if (intersect.object == objs[6]  && clicked == true) {
          clicked = false;
          console.log('show menu 7');
          document.querySelector('canvas.canvasGL').style.display = "none";
          document.getElementById('back').style.display = "none";
          document.getElementById('backPhoto').style.display = "block";
          document.getElementById('photoWindow').style.display = "block";

          document.getElementById('images1').style.display = "none";
          document.getElementById('images2').style.display = "none";
          document.getElementById('images3').style.display = "none";
          document.getElementById('images4').style.display = "none";
          document.getElementById('images5').style.display = "none";
          document.getElementById('images6').style.display = "none";
          document.getElementById('images7').style.display = "block";
          document.getElementById('images8').style.display = "none";
          document.getElementById('images9').style.display = "none";
          document.getElementById('images10').style.display = "none";
          document.getElementById('images11').style.display = "none";
          document.getElementById('images12').style.display = "none";
          document.getElementById('images13').style.display = "none";
          document.getElementById('images14').style.display = "none";
          document.getElementById('images15').style.display = "none";
          document.getElementById('images16').style.display = "none";
          document.getElementById('images17').style.display = "none";
          document.getElementById('images18').style.display = "none";
          document.getElementById('images19').style.display = "none";
          document.getElementById('images20').style.display = "none";
          document.getElementById('images21').style.display = "none";
          document.getElementById('images22').style.display = "none";
          document.getElementById('images23').style.display = "none";
          document.getElementById('images24').style.display = "none";
          document.getElementById('images25').style.display = "none";
      }
      else if (intersect.object == objs[7]  && clicked == true) {
        clicked = false;
        console.log('show menu 8');
        document.querySelector('canvas.canvasGL').style.display = "none";
        document.getElementById('back').style.display = "none";
        document.getElementById('backPhoto').style.display = "block";
        document.getElementById('photoWindow').style.display = "block";

        document.getElementById('images1').style.display = "none";
        document.getElementById('images2').style.display = "none";
        document.getElementById('images3').style.display = "none";
        document.getElementById('images4').style.display = "none";
        document.getElementById('images5').style.display = "none";
        document.getElementById('images6').style.display = "none";
        document.getElementById('images7').style.display = "none";
        document.getElementById('images8').style.display = "block";
        document.getElementById('images9').style.display = "none";
        document.getElementById('images10').style.display = "none";
        document.getElementById('images11').style.display = "none";
        document.getElementById('images12').style.display = "none";
        document.getElementById('images13').style.display = "none";
        document.getElementById('images14').style.display = "none";
        document.getElementById('images15').style.display = "none";
        document.getElementById('images16').style.display = "none";
        document.getElementById('images17').style.display = "none";
        document.getElementById('images18').style.display = "none";
        document.getElementById('images19').style.display = "none";
        document.getElementById('images20').style.display = "none";
        document.getElementById('images21').style.display = "none";
        document.getElementById('images22').style.display = "none";
        document.getElementById('images23').style.display = "none";
        document.getElementById('images24').style.display = "none";
        document.getElementById('images25').style.display = "none";
    }
    else if (intersect.object == objs[8]  && clicked == true) {
      clicked = false;
      console.log('show menu 9');
      document.querySelector('canvas.canvasGL').style.display = "none";
      document.getElementById('back').style.display = "none";
      document.getElementById('backPhoto').style.display = "block";
      document.getElementById('photoWindow').style.display = "block";

      document.getElementById('images1').style.display = "none";
      document.getElementById('images2').style.display = "none";
      document.getElementById('images3').style.display = "none";
      document.getElementById('images4').style.display = "none";
      document.getElementById('images5').style.display = "none";
      document.getElementById('images6').style.display = "none";
      document.getElementById('images7').style.display = "none";
      document.getElementById('images8').style.display = "none";
      document.getElementById('images9').style.display = "block";
      document.getElementById('images10').style.display = "none";
      document.getElementById('images11').style.display = "none";
      document.getElementById('images12').style.display = "none";
      document.getElementById('images13').style.display = "none";
      document.getElementById('images14').style.display = "none";
      document.getElementById('images15').style.display = "none";
      document.getElementById('images16').style.display = "none";
      document.getElementById('images17').style.display = "none";
      document.getElementById('images18').style.display = "none";
      document.getElementById('images19').style.display = "none";
      document.getElementById('images20').style.display = "none";
      document.getElementById('images21').style.display = "none";
      document.getElementById('images22').style.display = "none";
      document.getElementById('images23').style.display = "none";
      document.getElementById('images24').style.display = "none";
      document.getElementById('images25').style.display = "none";
  }
  else if (intersect.object == objs[9]  && clicked == true) {
    clicked = false;
    console.log('show menu 10');
    document.querySelector('canvas.canvasGL').style.display = "none";
    document.getElementById('back').style.display = "none";
    document.getElementById('backPhoto').style.display = "block";
    document.getElementById('photoWindow').style.display = "block";
    window.open("https://katalok.vercel.app");

    document.getElementById('images1').style.display = "none";
    document.getElementById('images2').style.display = "none";
    document.getElementById('images3').style.display = "none";
    document.getElementById('images4').style.display = "none";
    document.getElementById('images5').style.display = "none";
    document.getElementById('images6').style.display = "none";
    document.getElementById('images7').style.display = "none";
    document.getElementById('images8').style.display = "none";
    document.getElementById('images9').style.display = "none";
    document.getElementById('images10').style.display = "block";
    document.getElementById('images11').style.display = "none";
    document.getElementById('images12').style.display = "none";
    document.getElementById('images13').style.display = "none";
    document.getElementById('images14').style.display = "none";
    document.getElementById('images15').style.display = "none";
    document.getElementById('images16').style.display = "none";
    document.getElementById('images17').style.display = "none";
    document.getElementById('images18').style.display = "none";
    document.getElementById('images19').style.display = "none";
    document.getElementById('images20').style.display = "none";
    document.getElementById('images21').style.display = "none";
    document.getElementById('images22').style.display = "none";
    document.getElementById('images23').style.display = "none";
    document.getElementById('images24').style.display = "none";
    document.getElementById('images25').style.display = "none";
}
else if (intersect.object == objs[10]  && clicked == true) {
  clicked = false;
  console.log('show menu 10');
  document.querySelector('canvas.canvasGL').style.display = "none";
  document.getElementById('back').style.display = "none";
  document.getElementById('backPhoto').style.display = "block";
  document.getElementById('photoWindow').style.display = "block";

  document.getElementById('images1').style.display = "none";
  document.getElementById('images2').style.display = "none";
  document.getElementById('images3').style.display = "none";
  document.getElementById('images4').style.display = "none";
  document.getElementById('images5').style.display = "none";
  document.getElementById('images6').style.display = "none";
  document.getElementById('images7').style.display = "none";
  document.getElementById('images8').style.display = "none";
  document.getElementById('images9').style.display = "none";
  document.getElementById('images10').style.display = "none";
  document.getElementById('images11').style.display = "block";
  document.getElementById('images12').style.display = "none";
  document.getElementById('images13').style.display = "none";
  document.getElementById('images14').style.display = "none";
  document.getElementById('images15').style.display = "none";
  document.getElementById('images16').style.display = "none";
  document.getElementById('images17').style.display = "none";
  document.getElementById('images18').style.display = "none";
  document.getElementById('images19').style.display = "none";
  document.getElementById('images20').style.display = "none";
  document.getElementById('images21').style.display = "none";
  document.getElementById('images22').style.display = "none";
  document.getElementById('images23').style.display = "none";
  document.getElementById('images24').style.display = "none";
  document.getElementById('images25').style.display = "none";
}
else if (intersect.object == objs[11]  && clicked == true) {
  clicked = false;
  console.log('show menu 10');
  document.querySelector('canvas.canvasGL').style.display = "none";
  document.getElementById('back').style.display = "none";
  document.getElementById('backPhoto').style.display = "block";
  document.getElementById('photoWindow').style.display = "block";

  document.getElementById('images1').style.display = "none";
  document.getElementById('images2').style.display = "none";
  document.getElementById('images3').style.display = "none";
  document.getElementById('images4').style.display = "none";
  document.getElementById('images5').style.display = "none";
  document.getElementById('images6').style.display = "none";
  document.getElementById('images7').style.display = "none";
  document.getElementById('images8').style.display = "none";
  document.getElementById('images9').style.display = "none";
  document.getElementById('images10').style.display = "none";
  document.getElementById('images11').style.display = "none";
  document.getElementById('images12').style.display = "block";
  document.getElementById('images13').style.display = "none";
  document.getElementById('images14').style.display = "none";
  document.getElementById('images15').style.display = "none";
  document.getElementById('images16').style.display = "none";
  document.getElementById('images17').style.display = "none";
  document.getElementById('images18').style.display = "none";
  document.getElementById('images19').style.display = "none";
  document.getElementById('images20').style.display = "none";
  document.getElementById('images21').style.display = "none";
  document.getElementById('images22').style.display = "none";
  document.getElementById('images23').style.display = "none";
  document.getElementById('images24').style.display = "none";
  document.getElementById('images25').style.display = "none";
}
else if (intersect.object == objs[12]  && clicked == true) {
  clicked = false;
  console.log('show menu 10');
  document.querySelector('canvas.canvasGL').style.display = "none";
  document.getElementById('back').style.display = "none";
  document.getElementById('backPhoto').style.display = "block";
  document.getElementById('photoWindow').style.display = "block";

  document.getElementById('images1').style.display = "none";
  document.getElementById('images2').style.display = "none";
  document.getElementById('images3').style.display = "none";
  document.getElementById('images4').style.display = "none";
  document.getElementById('images5').style.display = "none";
  document.getElementById('images6').style.display = "none";
  document.getElementById('images7').style.display = "none";
  document.getElementById('images8').style.display = "none";
  document.getElementById('images9').style.display = "none";
  document.getElementById('images10').style.display = "none";
  document.getElementById('images11').style.display = "none";
  document.getElementById('images12').style.display = "none";
  document.getElementById('images13').style.display = "block";
  document.getElementById('images14').style.display = "none";
  document.getElementById('images15').style.display = "none";
  document.getElementById('images16').style.display = "none";
  document.getElementById('images17').style.display = "none";
  document.getElementById('images18').style.display = "none";
  document.getElementById('images19').style.display = "none";
  document.getElementById('images20').style.display = "none";
  document.getElementById('images21').style.display = "none";
  document.getElementById('images22').style.display = "none";
  document.getElementById('images23').style.display = "none";
  document.getElementById('images24').style.display = "none";
  document.getElementById('images25').style.display = "none";
}
else if (intersect.object == objs[13]  && clicked == true) {
  clicked = false;
  console.log('show menu 10');
  document.querySelector('canvas.canvasGL').style.display = "none";
  document.getElementById('back').style.display = "none";
  document.getElementById('backPhoto').style.display = "block";
  document.getElementById('photoWindow').style.display = "block";

  document.getElementById('images1').style.display = "none";
  document.getElementById('images2').style.display = "none";
  document.getElementById('images3').style.display = "none";
  document.getElementById('images4').style.display = "none";
  document.getElementById('images5').style.display = "none";
  document.getElementById('images6').style.display = "none";
  document.getElementById('images7').style.display = "none";
  document.getElementById('images8').style.display = "none";
  document.getElementById('images9').style.display = "none";
  document.getElementById('images10').style.display = "none";
  document.getElementById('images11').style.display = "none";
  document.getElementById('images12').style.display = "none";
  document.getElementById('images13').style.display = "none";
  document.getElementById('images14').style.display = "block";
  document.getElementById('images15').style.display = "none";
  document.getElementById('images16').style.display = "none";
  document.getElementById('images17').style.display = "none";
  document.getElementById('images18').style.display = "none";
  document.getElementById('images19').style.display = "none";
  document.getElementById('images20').style.display = "none";
  document.getElementById('images21').style.display = "none";
  document.getElementById('images22').style.display = "none";
  document.getElementById('images23').style.display = "none";
  document.getElementById('images24').style.display = "none";
  document.getElementById('images25').style.display = "none";
}
else if (intersect.object == objs[14]  && clicked == true) {
  clicked = false;
  console.log('show menu 10');
  document.querySelector('canvas.canvasGL').style.display = "none";
  document.getElementById('back').style.display = "none";
  document.getElementById('backPhoto').style.display = "block";
  document.getElementById('photoWindow').style.display = "block";

  document.getElementById('images1').style.display = "none";
  document.getElementById('images2').style.display = "none";
  document.getElementById('images3').style.display = "none";
  document.getElementById('images4').style.display = "none";
  document.getElementById('images5').style.display = "none";
  document.getElementById('images6').style.display = "none";
  document.getElementById('images7').style.display = "none";
  document.getElementById('images8').style.display = "none";
  document.getElementById('images9').style.display = "none";
  document.getElementById('images10').style.display = "none";
  document.getElementById('images11').style.display = "none";
  document.getElementById('images12').style.display = "none";
  document.getElementById('images13').style.display = "none";
  document.getElementById('images14').style.display = "none";
  document.getElementById('images15').style.display = "block";
  document.getElementById('images16').style.display = "none";
  document.getElementById('images17').style.display = "none";
  document.getElementById('images18').style.display = "none";
  document.getElementById('images19').style.display = "none";
  document.getElementById('images20').style.display = "none";
  document.getElementById('images21').style.display = "none";
  document.getElementById('images22').style.display = "none";
  document.getElementById('images23').style.display = "none";
  document.getElementById('images24').style.display = "none";
  document.getElementById('images25').style.display = "none";
}
else if (intersect.object == objs[15]  && clicked == true) {
  clicked = false;
  console.log('show menu 10');
  document.querySelector('canvas.canvasGL').style.display = "none";
  document.getElementById('back').style.display = "none";
  document.getElementById('backPhoto').style.display = "block";
  document.getElementById('photoWindow').style.display = "block";
  window.open("https://katalok.vercel.app");

  document.getElementById('images1').style.display = "none";
  document.getElementById('images2').style.display = "none";
  document.getElementById('images3').style.display = "none";
  document.getElementById('images4').style.display = "none";
  document.getElementById('images5').style.display = "none";
  document.getElementById('images6').style.display = "none";
  document.getElementById('images7').style.display = "none";
  document.getElementById('images8').style.display = "none";
  document.getElementById('images9').style.display = "none";
  document.getElementById('images10').style.display = "none";
  document.getElementById('images11').style.display = "none";
  document.getElementById('images12').style.display = "none";
  document.getElementById('images13').style.display = "none";
  document.getElementById('images14').style.display = "none";
  document.getElementById('images15').style.display = "none";
  document.getElementById('images16').style.display = "block";
  document.getElementById('images17').style.display = "none";
  document.getElementById('images18').style.display = "none";
  document.getElementById('images19').style.display = "none";
  document.getElementById('images20').style.display = "none";
  document.getElementById('images21').style.display = "none";
  document.getElementById('images22').style.display = "none";
  document.getElementById('images23').style.display = "none";
  document.getElementById('images24').style.display = "none";
  document.getElementById('images25').style.display = "none";
}
else if (intersect.object == objs[16]  && clicked == true) {
  clicked = false;
  console.log('show menu 10');
  document.querySelector('canvas.canvasGL').style.display = "none";
  document.getElementById('back').style.display = "none";
  document.getElementById('backPhoto').style.display = "block";
  document.getElementById('photoWindow').style.display = "block";

  document.getElementById('images1').style.display = "none";
  document.getElementById('images2').style.display = "none";
  document.getElementById('images3').style.display = "none";
  document.getElementById('images4').style.display = "none";
  document.getElementById('images5').style.display = "none";
  document.getElementById('images6').style.display = "none";
  document.getElementById('images7').style.display = "none";
  document.getElementById('images8').style.display = "none";
  document.getElementById('images9').style.display = "none";
  document.getElementById('images10').style.display = "none";
  document.getElementById('images11').style.display = "none";
  document.getElementById('images12').style.display = "none";
  document.getElementById('images13').style.display = "none";
  document.getElementById('images14').style.display = "none";
  document.getElementById('images15').style.display = "none";
  document.getElementById('images16').style.display = "none";
  document.getElementById('images17').style.display = "block";
  document.getElementById('images18').style.display = "none";
  document.getElementById('images19').style.display = "none";
  document.getElementById('images20').style.display = "none";
  document.getElementById('images21').style.display = "none";
  document.getElementById('images22').style.display = "none";
  document.getElementById('images23').style.display = "none";
  document.getElementById('images24').style.display = "none";
  document.getElementById('images25').style.display = "none";
}
else if (intersect.object == objs[17]  && clicked == true) {
  clicked = false;
  console.log('show menu 10');
  document.querySelector('canvas.canvasGL').style.display = "none";
  document.getElementById('back').style.display = "none";
  document.getElementById('backPhoto').style.display = "block";
  document.getElementById('photoWindow').style.display = "block";

  document.getElementById('images1').style.display = "none";
  document.getElementById('images2').style.display = "none";
  document.getElementById('images3').style.display = "none";
  document.getElementById('images4').style.display = "none";
  document.getElementById('images5').style.display = "none";
  document.getElementById('images6').style.display = "none";
  document.getElementById('images7').style.display = "none";
  document.getElementById('images8').style.display = "none";
  document.getElementById('images9').style.display = "none";
  document.getElementById('images10').style.display = "none";
  document.getElementById('images11').style.display = "none";
  document.getElementById('images12').style.display = "none";
  document.getElementById('images13').style.display = "none";
  document.getElementById('images14').style.display = "none";
  document.getElementById('images15').style.display = "none";
  document.getElementById('images16').style.display = "none";
  document.getElementById('images17').style.display = "none";
  document.getElementById('images18').style.display = "block";
  document.getElementById('images19').style.display = "none";
  document.getElementById('images20').style.display = "none";
  document.getElementById('images21').style.display = "none";
  document.getElementById('images22').style.display = "none";
  document.getElementById('images23').style.display = "none";
  document.getElementById('images24').style.display = "none";
  document.getElementById('images25').style.display = "none";
}
else if (intersect.object == objs[18]  && clicked == true) {
  clicked = false;
  console.log('show menu 10');
  document.querySelector('canvas.canvasGL').style.display = "none";
  document.getElementById('back').style.display = "none";
  document.getElementById('backPhoto').style.display = "block";
  document.getElementById('photoWindow').style.display = "block";

  document.getElementById('images1').style.display = "none";
  document.getElementById('images2').style.display = "none";
  document.getElementById('images3').style.display = "none";
  document.getElementById('images4').style.display = "none";
  document.getElementById('images5').style.display = "none";
  document.getElementById('images6').style.display = "none";
  document.getElementById('images7').style.display = "none";
  document.getElementById('images8').style.display = "none";
  document.getElementById('images9').style.display = "none";
  document.getElementById('images10').style.display = "none";
  document.getElementById('images11').style.display = "none";
  document.getElementById('images12').style.display = "none";
  document.getElementById('images13').style.display = "none";
  document.getElementById('images14').style.display = "none";
  document.getElementById('images15').style.display = "none";
  document.getElementById('images16').style.display = "none";
  document.getElementById('images17').style.display = "none";
  document.getElementById('images18').style.display = "none";
  document.getElementById('images19').style.display = "block";
  document.getElementById('images20').style.display = "none";
  document.getElementById('images21').style.display = "none";
  document.getElementById('images22').style.display = "none";
  document.getElementById('images23').style.display = "none";
  document.getElementById('images24').style.display = "none";
  document.getElementById('images25').style.display = "none";
}
else if (intersect.object == objs[19]  && clicked == true) {
  clicked = false;
  console.log('show menu 10');
  document.querySelector('canvas.canvasGL').style.display = "none";
  document.getElementById('back').style.display = "none";
  document.getElementById('backPhoto').style.display = "block";
  document.getElementById('photoWindow').style.display = "block";

  document.getElementById('images1').style.display = "none";
  document.getElementById('images2').style.display = "none";
  document.getElementById('images3').style.display = "none";
  document.getElementById('images4').style.display = "none";
  document.getElementById('images5').style.display = "none";
  document.getElementById('images6').style.display = "none";
  document.getElementById('images7').style.display = "none";
  document.getElementById('images8').style.display = "none";
  document.getElementById('images9').style.display = "none";
  document.getElementById('images10').style.display = "none";
  document.getElementById('images11').style.display = "none";
  document.getElementById('images12').style.display = "none";
  document.getElementById('images13').style.display = "none";
  document.getElementById('images14').style.display = "none";
  document.getElementById('images15').style.display = "none";
  document.getElementById('images16').style.display = "none";
  document.getElementById('images17').style.display = "none";
  document.getElementById('images18').style.display = "none";
  document.getElementById('images19').style.display = "none";
  document.getElementById('images20').style.display = "block";
  document.getElementById('images21').style.display = "none";
  document.getElementById('images22').style.display = "none";
  document.getElementById('images23').style.display = "none";
  document.getElementById('images24').style.display = "none";
  document.getElementById('images25').style.display = "none";
}
else if (intersect.object == objs[20]  && clicked == true) {
  clicked = false;
  console.log('show menu 10');
  document.querySelector('canvas.canvasGL').style.display = "none";
  document.getElementById('back').style.display = "none";
  document.getElementById('backPhoto').style.display = "block";
  document.getElementById('photoWindow').style.display = "block";

  document.getElementById('images1').style.display = "none";
  document.getElementById('images2').style.display = "none";
  document.getElementById('images3').style.display = "none";
  document.getElementById('images4').style.display = "none";
  document.getElementById('images5').style.display = "none";
  document.getElementById('images6').style.display = "none";
  document.getElementById('images7').style.display = "none";
  document.getElementById('images8').style.display = "none";
  document.getElementById('images9').style.display = "none";
  document.getElementById('images10').style.display = "none";
  document.getElementById('images11').style.display = "none";
  document.getElementById('images12').style.display = "none";
  document.getElementById('images13').style.display = "none";
  document.getElementById('images14').style.display = "none";
  document.getElementById('images15').style.display = "none";
  document.getElementById('images16').style.display = "none";
  document.getElementById('images17').style.display = "none";
  document.getElementById('images18').style.display = "none";
  document.getElementById('images19').style.display = "none";
  document.getElementById('images20').style.display = "none";
  document.getElementById('images21').style.display = "block";
  document.getElementById('images22').style.display = "none";
  document.getElementById('images23').style.display = "none";
  document.getElementById('images24').style.display = "none";
  document.getElementById('images25').style.display = "none";
}
else if (intersect.object == objs[21]  && clicked == true) {
  clicked = false;
  console.log('show menu 10');
  document.querySelector('canvas.canvasGL').style.display = "none";
  document.getElementById('back').style.display = "none";
  document.getElementById('backPhoto').style.display = "block";
  document.getElementById('photoWindow').style.display = "block";

  document.getElementById('images1').style.display = "none";
  document.getElementById('images2').style.display = "none";
  document.getElementById('images3').style.display = "none";
  document.getElementById('images4').style.display = "none";
  document.getElementById('images5').style.display = "none";
  document.getElementById('images6').style.display = "none";
  document.getElementById('images7').style.display = "none";
  document.getElementById('images8').style.display = "none";
  document.getElementById('images9').style.display = "none";
  document.getElementById('images10').style.display = "none";
  document.getElementById('images11').style.display = "none";
  document.getElementById('images12').style.display = "none";
  document.getElementById('images13').style.display = "none";
  document.getElementById('images14').style.display = "none";
  document.getElementById('images15').style.display = "none";
  document.getElementById('images16').style.display = "none";
  document.getElementById('images17').style.display = "none";
  document.getElementById('images18').style.display = "none";
  document.getElementById('images19').style.display = "none";
  document.getElementById('images20').style.display = "none";
  document.getElementById('images21').style.display = "none";
  document.getElementById('images22').style.display = "block";
  document.getElementById('images23').style.display = "none";
  document.getElementById('images24').style.display = "none";
  document.getElementById('images25').style.display = "none";
}
else if (intersect.object == objs[22]  && clicked == true) {
  clicked = false;
  console.log('show menu 10');
  document.querySelector('canvas.canvasGL').style.display = "none";
  document.getElementById('back').style.display = "none";
  document.getElementById('backPhoto').style.display = "block";
  document.getElementById('photoWindow').style.display = "block";

  document.getElementById('images1').style.display = "none";
  document.getElementById('images2').style.display = "none";
  document.getElementById('images3').style.display = "none";
  document.getElementById('images4').style.display = "none";
  document.getElementById('images5').style.display = "none";
  document.getElementById('images6').style.display = "none";
  document.getElementById('images7').style.display = "none";
  document.getElementById('images8').style.display = "none";
  document.getElementById('images9').style.display = "none";
  document.getElementById('images10').style.display = "none";
  document.getElementById('images11').style.display = "none";
  document.getElementById('images12').style.display = "none";
  document.getElementById('images13').style.display = "none";
  document.getElementById('images14').style.display = "none";
  document.getElementById('images15').style.display = "none";
  document.getElementById('images16').style.display = "none";
  document.getElementById('images17').style.display = "none";
  document.getElementById('images18').style.display = "none";
  document.getElementById('images19').style.display = "none";
  document.getElementById('images20').style.display = "none";
  document.getElementById('images21').style.display = "none";
  document.getElementById('images22').style.display = "none";
  document.getElementById('images23').style.display = "block";
  document.getElementById('images24').style.display = "none";
  document.getElementById('images25').style.display = "none";
}
else if (intersect.object == objs[23]  && clicked == true) {
  clicked = false;
  console.log('show menu 10');
  document.querySelector('canvas.canvasGL').style.display = "none";
  document.getElementById('back').style.display = "none";
  document.getElementById('backPhoto').style.display = "block";
  document.getElementById('photoWindow').style.display = "block";

  document.getElementById('images1').style.display = "none";
  document.getElementById('images2').style.display = "none";
  document.getElementById('images3').style.display = "none";
  document.getElementById('images4').style.display = "none";
  document.getElementById('images5').style.display = "none";
  document.getElementById('images6').style.display = "none";
  document.getElementById('images7').style.display = "none";
  document.getElementById('images8').style.display = "none";
  document.getElementById('images9').style.display = "none";
  document.getElementById('images10').style.display = "none";
  document.getElementById('images11').style.display = "none";
  document.getElementById('images12').style.display = "none";
  document.getElementById('images13').style.display = "none";
  document.getElementById('images14').style.display = "none";
  document.getElementById('images15').style.display = "none";
  document.getElementById('images16').style.display = "none";
  document.getElementById('images17').style.display = "none";
  document.getElementById('images18').style.display = "none";
  document.getElementById('images19').style.display = "none";
  document.getElementById('images20').style.display = "none";
  document.getElementById('images21').style.display = "none";
  document.getElementById('images22').style.display = "none";
  document.getElementById('images23').style.display = "none";
  document.getElementById('images24').style.display = "block";
  document.getElementById('images25').style.display = "none";
}
else if (intersect.object == objs[24]  && clicked == true) {
  clicked = false;
  console.log('show menu 10');
  document.querySelector('canvas.canvasGL').style.display = "none";
  document.getElementById('back').style.display = "none";
  document.getElementById('backPhoto').style.display = "block";
  document.getElementById('photoWindow').style.display = "block";

  document.getElementById('images1').style.display = "none";
  document.getElementById('images2').style.display = "none";
  document.getElementById('images3').style.display = "none";
  document.getElementById('images4').style.display = "none";
  document.getElementById('images5').style.display = "none";
  document.getElementById('images6').style.display = "none";
  document.getElementById('images7').style.display = "none";
  document.getElementById('images8').style.display = "none";
  document.getElementById('images9').style.display = "none";
  document.getElementById('images10').style.display = "none";
  document.getElementById('images11').style.display = "none";
  document.getElementById('images12').style.display = "none";
  document.getElementById('images13').style.display = "none";
  document.getElementById('images14').style.display = "none";
  document.getElementById('images15').style.display = "none";
  document.getElementById('images16').style.display = "none";
  document.getElementById('images17').style.display = "none";
  document.getElementById('images18').style.display = "none";
  document.getElementById('images19').style.display = "none";
  document.getElementById('images20').style.display = "none";
  document.getElementById('images21').style.display = "none";
  document.getElementById('images22').style.display = "none";
  document.getElementById('images23').style.display = "none";
  document.getElementById('images24').style.display = "none";
  document.getElementById('images25').style.display = "block";
}
        }
    }
}

function gui() {
  // Debug
  const gui = new dat.GUI()
      // let modelFolder = gui.addFolder('3DModel')
      // modelFolder.add(mod.position, 'z', 0, Math.PI * 2)
      // modelFolder.open()
  let camFolder = gui.addFolder('Camera')
  camFolder.add(camera.position, 'x', 0, 10).listen();

  camFolder.add(camera.position, 'y', 0, 10).listen();

  camFolder.add(camera.position, 'z', 0, 10).listen();
  console.log('Camera folder is added')
  camFolder.open()

  let animFolder = gui.addFolder('Anim')
      // animFolder.add(gt, 'x', 0, 10).listen();
      // animFolder.open()
}

// gui();
onWindowResize();

// KEZIA
function character() {
  //Model Loader
  
  let gltfLoader = new GLTFLoader();
  let mod;
  gltfLoader.load('3d/eka.glb',
      function(gltf) {
          mod = gltf.scene;
          mod.position.z = 0;
          mod.position.x = 0;
          mod.position.y = 3;
          mod.scale.set(4,4,4);

          mixer = new THREE.AnimationMixer(mod);
          console.log(mod);
          const action = mixer.clipAction(gltf.animations[0])
          action.setLoop(THREE.LoopRepeat);
          action.clampWhenFinished = true;
          action.enable = true;

          action.play();
          console.log('ACTION', action);

          // animate()
          console.log('3d loaded');
          sceneBio.add(mod);
      },
      // called while loading is progressing
      function(xhr) {
          console.log((xhr.loaded / xhr.total * 100) + '% 3D model loaded');
          if (xhr.loaded / xhr.total == 1) {
              // console.log('trigger loaded',document.getElementById('loading').style);
              // document.querySelector('canvas').style.visibility = "visible";

          } else {
              // document.querySelector('canvas').style.visibility = "hidden";
          }
      },
      // called when loading has errors
      function(error) {
          console.log('An error happened????');

      });
}
character();


// dragging Images
// // target elements with the "draggable" class
// const position = { x: 0, y: 0 }

// interact('.draggable').draggable({
//   listeners: {
//     start (event) {
//       console.log('drag',event.type, event.target)
//     },
//     move (event) {
//       position.x += event.dx
//       position.y += event.dy

//       event.target.style.transform =
//         `translate(${position.x}px, ${position.y}px)`
//     },
//   }
// })

// CV
document.getElementById('cvButton').addEventListener('pointerdown', (e) => {
  document.getElementById('cvContent').style.display = "block";
  document.getElementById('bio').style.display = "none";

}) 



